import { useCallback, useState } from 'react'

type TooltipData<T> = {
  top: number
  left: number
  data: T
}

export function useTooltip<T>() {
  const [tooltipData, setTooltipData] = useState<TooltipData<T> | null>(null)

  const showTooltip = useCallback((data: TooltipData<T>) => {
    setTooltipData(data)
  }, [])

  const hideTooltip = useCallback(() => {
    setTooltipData(null)
  }, [])

  return { tooltipData, showTooltip, hideTooltip }
}
