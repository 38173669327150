import { MouseEvent } from 'react'
import * as d3 from 'd3'
import { addDays, format, isAfter } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { chartColors, emptyColors } from '@/constants/chartConfig'
import { cn } from '@/utils/classnames'
import { formatNumberShort } from '@/utils/formatter'
import { useTooltip } from '@/hooks/useTooltip'
import ResponsiveContainer from '@/components/chart/ResponsiveContainer'
import Tooltip from '@/components/chart/Tooltip'

export type LineItem = {
  date: Date
  [key: string]: any
}

type TooltipData = LineItem

const mockData: LineItem[] = Array.from({ length: 30 }, (v, i) => ({
  date: addDays(new Date(), i),
  test1: Math.floor(Math.random() * Math.pow(10, 6)) + Math.pow(10, 5),
  test2: Math.floor(Math.random() * Math.pow(10, 6)) + Math.pow(10, 6)
}))

const emptyData: LineItem[] = [
  {
    date: addDays(new Date(), 0),
    mock1: 23000000,
    mock2: 16500000,
    mock3: 9600000,
    mock4: 3200000
  },
  {
    date: addDays(new Date(), 1),
    mock1: 26000000,
    mock2: 19800000,
    mock3: 11512000,
    mock4: 4080000
  },
  {
    date: addDays(new Date(), 2),
    mock1: 27200000,
    mock2: 21120000,
    mock3: 12000000,
    mock4: 3520000
  },
  {
    date: addDays(new Date(), 3),
    mock1: 28000000,
    mock2: 22000000,
    mock3: 12240000,
    mock4: 4840000
  },
  {
    date: addDays(new Date(), 4),
    mock1: 27200000,
    mock2: 21120000,
    mock3: 14160000,
    mock4: 7040000
  },
  {
    date: addDays(new Date(), 5),
    mock1: 26000000,
    mock2: 19800000,
    mock3: 14400000,
    mock4: 7920000
  },
  {
    date: addDays(new Date(), 6),
    mock1: 26000000,
    mock2: 19800000,
    mock3: 12000000,
    mock4: 4400000
  },
  {
    date: addDays(new Date(), 7),
    mock1: 25600000,
    mock2: 19360000,
    mock3: 12480000,
    mock4: 5280000
  },
  {
    date: addDays(new Date(), 8),
    mock1: 30000000,
    mock2: 24200000,
    mock3: 15120000,
    mock4: 10120000
  },
  {
    date: addDays(new Date(), 9),
    mock1: 26000000,
    mock2: 19800000,
    mock3: 13920000,
    mock4: 7920000
  }
]

const MARGIN = { top: 36, right: 4, bottom: 28, left: 40 }

interface LineChartProps {
  data?: LineItem[]
  unit?: string
  formatter?: (value: string, unit: string) => string
}

export function LineChart({
  data = [],
  unit = 'USD',
  formatter = (value, unit) => `${unit} ${value}`
}: LineChartProps) {
  const { t } = useTranslation()
  const { tooltipData, showTooltip, hideTooltip } = useTooltip<TooltipData>()
  const isEmpty = data.length === 0
  const dataSource = (isEmpty ? emptyData : data).sort((a, b) => (isAfter(a.date, b.date) ? 1 : -1))
  const colors = isEmpty ? emptyColors : chartColors

  const handleMouseMove = (e: MouseEvent<SVGRectElement>, data: TooltipData) => {
    const left = e.clientX + 260
    showTooltip({
      top: e.clientY - 50,
      left: left > window.innerWidth ? e.clientX - 260 : e.clientX + 20,
      data
    })
  }

  return (
    <>
      <ResponsiveContainer width={'100%'} height={400}>
        {({ width, height }) => {
          // space of chart
          const bounds = {
            width: width - (MARGIN.right + MARGIN.left),
            height: height - (MARGIN.top + MARGIN.bottom)
          }

          // scales
          const xTicks = d3.extent(dataSource, (d) => d.date) as [Date, Date]
          const xScale = d3.scaleTime().domain(xTicks).range([0, bounds.width])
          const allKeys = Object.keys(dataSource[0]).filter((key) => key !== 'date')
          const keysMax = d3.map(allKeys, (k) => d3.max(dataSource, (d) => d[k] as number))
          const keyMin = d3.map(allKeys, (k) => d3.min(dataSource, (d) => d[k] as number))
          const maxValue = Math.max(d3.max(keysMax, (d) => d)! * 1.2, 0)
          const minValue = Math.min(d3.min(keyMin, (d) => d)! * 1.2, 0)
          const yScale = d3.scaleLinear().domain([minValue, maxValue]).range([bounds.height, 0])

          // axis
          const xAxis = xScale
            .ticks()
            .filter((_item, index) => index % 2 === 0)
            .map((label) => ({
              label,
              x: xScale(label)!
            }))
          const yAxis = yScale
            .ticks()
            .filter((_item, index) => index % 2 === 0)
            .map((label) => ({
              label,
              y: yScale(label)
            }))

          // line paths
          const lines = allKeys.map((key) => {
            const areaPath = d3
              .area<LineItem>()
              .x((d) => xScale(d.date)!)
              .y1((d) => yScale(d[key] as number))
              .y0(yScale(0))(dataSource)
            const linePath = d3
              .line<LineItem>()
              .x((d) => xScale(d.date)!)
              .y((d) => yScale(d[key] as number))(dataSource)
            return { key, areaPath, linePath }
          })

          // pressable rects
          const rects = Array.from({ length: bounds.width }, (v, i) => {
            const scale = d3
              .scaleLinear()
              .domain([0, bounds.width])
              .range([0, dataSource.length - 1])
            const index = Math.round(scale(i))
            return {
              label: xScale.invert(i),
              x: i,
              y: 0,
              width: 1,
              height: bounds.height,
              data: dataSource[index]
            }
          })

          return (
            <svg width={width} height={height}>
              {/* X axis */}
              <g transform={`translate(${MARGIN.left},${MARGIN.top + bounds.height})`}>
                {xAxis.map((item, index) => (
                  <text
                    key={index}
                    x={item.x}
                    y={MARGIN.bottom}
                    textAnchor={'middle'}
                    fill={'#BCBEC9'}
                    opacity={isEmpty ? 0 : 1}
                  >
                    {format(item.label, 'MM/dd')}
                  </text>
                ))}
              </g>

              {/* Y axis */}
              <g transform={`translate(0,${MARGIN.top})`}>
                <text x={MARGIN.left - 4} y={-14} textAnchor={'end'} fill={'#BCBEC9'}>
                  {unit}
                </text>
                {yAxis.map((item, index) => (
                  <text key={index} x={MARGIN.left - 4} y={item.y + 4} textAnchor={'end'} fill={'#BCBEC9'}>
                    {formatNumberShort(item.label)}
                  </text>
                ))}
              </g>

              {/* Cartesian Grid */}
              <g transform={`translate(${MARGIN.left},${MARGIN.top})`}>
                {yAxis.map((item, index) => (
                  <line
                    key={index}
                    x1={0}
                    x2={bounds.width}
                    y1={item.y}
                    y2={item.y}
                    stroke={'#BCBEC9'}
                    strokeWidth={1}
                    strokeDasharray={8}
                  />
                ))}
              </g>

              {/* Line Items */}
              <g transform={`translate(${MARGIN.left},${MARGIN.top})`}>
                {lines.map((item, index) => (
                  <Line
                    key={index}
                    areaPath={item.areaPath ?? ''}
                    linePath={item.linePath ?? ''}
                    color={colors[index % colors.length]}
                  />
                ))}
              </g>

              {/* Pressable Items */}
              <g transform={`translate(${MARGIN.left},${MARGIN.top})`}>
                {rects.map((item, index) => (
                  <rect
                    key={index}
                    className={cn(isEmpty && 'pointer-events-none')}
                    x={item.x}
                    y={item.y}
                    width={item.width}
                    height={item.height}
                    fill={'transparent'}
                    onMouseMove={(e) => handleMouseMove(e, item.data!)}
                    onMouseLeave={hideTooltip}
                  />
                ))}
              </g>

              {isEmpty && (
                <g transform={`translate(${width / 2},${height / 2})`}>
                  <text
                    y={6}
                    className={'drop-shadow'}
                    fill={'#fff'}
                    textAnchor={'middle'}
                    fontSize={'24'}
                    fontWeight={'bold'}
                  >
                    {t('NoData')}
                  </text>
                </g>
              )}
            </svg>
          )
        }}
      </ResponsiveContainer>
      {tooltipData && (
        <Tooltip top={tooltipData.top} left={tooltipData.left}>
          <p className={'text-white'}>{format(tooltipData.data.date, 'MM/dd')}</p>
          <ul className={'space-y-1'}>
            {Object.entries(tooltipData.data).map(
              ([key, value], index) =>
                key !== 'date' && (
                  <li key={index} className={'flex items-center justify-between'}>
                    <div className={'flex items-center gap-x-1'}>
                      <div
                        className={'my-0.5 h-4 w-4 rounded'}
                        style={{ backgroundColor: colors[index % colors.length] }}
                      />
                      <span className={'text-sm capitalize text-white'}>{key}</span>
                    </div>
                    <span className={'text-sm font-bold text-white'}>
                      {formatter(formatNumberShort(value as number), unit)}
                    </span>
                  </li>
                )
            )}
          </ul>
        </Tooltip>
      )}
    </>
  )
}

interface LineProps {
  areaPath: string
  linePath: string
  color: string
}

function Line({ areaPath, linePath, color }: LineProps) {
  return (
    <g>
      <path d={areaPath} opacity={1} stroke={'none'} fill={color} fillOpacity={0.2} />
      <path d={linePath} opacity={1} stroke={color} fill={'none'} strokeWidth={2} />
    </g>
  )
}
