import { ReactNode, useEffect, useRef, useState } from 'react'

import { cn } from '@/utils/classnames'

type Dimension = {
  width: number
  height: number
}

interface ResponsiveContainerProps {
  children: (dimension: Dimension) => ReactNode
  width: number | string
  height: number | string
  className?: string
}

export default function ResponsiveContainer({ children, width, height, className }: ResponsiveContainerProps) {
  const ref = useRef<HTMLDivElement>(null)
  const [dimension, setDimension] = useState<Dimension>({ width: 0, height: 0 })

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        const entry = entries[0]
        setDimension({
          width: entry.contentRect.width,
          height: entry.contentRect.height
        })
      }
    })
    if (ref.current) observer.observe(ref.current)
    return () => observer.disconnect()
  }, [])

  return (
    <div style={{ width, height }}>
      <div ref={ref} className={cn('relative h-full w-full', className)}>
        {children(dimension)}
      </div>
    </div>
  )
}
