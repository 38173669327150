import { ReactNode } from 'react'

import { cn } from '@/utils/classnames'

interface TooltipProps {
  children: ReactNode
  top: number
  left: number
  className?: string
}

export default function Tooltip({ children, top, left, className }: TooltipProps) {
  return (
    <div
      className={cn(
        'pointer-events-none fixed w-60 overflow-hidden rounded border border-white bg-background-main p-2',
        className
      )}
      style={{ top, left }}
    >
      {children}
    </div>
  )
}
